import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAOGXD66r__UFirW4HynOXczYpfYrbwxng",
    authDomain: "enklawa-workshops-board-48f97.firebaseapp.com",
    projectId: "enklawa-workshops-board-48f97",
    storageBucket: "enklawa-workshops-board-48f97.appspot.com",
    messagingSenderId: "91060884466",
    appId: "1:91060884466:web:e23a0e6643cddbe7f6916b",
    measurementId: "G-EL66Y8G48T"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { db, auth, analytics };
