import axios from 'axios';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../firebase';

// const DISCORD_WEBHOOK_URL = 'https://discord.com/api/webhooks/1272235162171740326/7O8o95YGDp7zuWJSpHyHY8UPEO_gIHLD3mlyEExVUrVKkFUulK0Q4kDfuGKGEljAPUHZ';

const DISCORD_WEBHOOK_URL_2 = 'https://discord.com/api/webhooks/1272600346019102884/eprrhxMy6pzsrg-51w9UG8BJjH0peSHj9o1T2Lm0c8eis3yO1uJEXYSECRaguNNvwmKF';


const fieldNamesMap = {
    title: 'Tytuł',
    description: 'Opis',
    day: 'Dzień',
    startTime: 'Początek',
    endTime: 'Koniec',
    category: 'Kategoria',
    location: 'Miejsce',
    instructor: 'Prowadzący'
};

export const logUserAction = async (action, workshop, user, previousWorkshop = null) => {
    const changes = {};

    if (previousWorkshop) {
        Object.keys(workshop).forEach(key => {
            if (workshop[key] !== previousWorkshop[key]) {
                changes[key] = {
                    oldValue: previousWorkshop[key] || 'brak',
                    newValue: workshop[key] || 'brak'
                };
            }
        });
    }

    await addDoc(collection(db, "user_actions"), {
        userId: user.uid,
        action: action,
        workshopId: workshop.id || null,
        workshopTitle: workshop.title || null,
        changes: previousWorkshop ? changes : null,
        timestamp: new Date(),
    });

    // const message = {
    //     content: `Użytkownik **${user.email}** wykonał akcję: **${action}** na karcie **"${workshop.title}"**.`,
    //     embeds: [
    //         {
    //             title: action === 'add' ? "Dodano nowy warsztat" : action === 'delete' ? "Usunięto warsztat" : "Zaktualizowano warsztat",
    //             description: action === 'add' || action === 'delete'
    //                 ? `**Tytuł:** ${workshop.title}\n**Dzień:** ${workshop.day}\n**Początek:** ${workshop.startTime}\n**Koniec:** ${workshop.endTime || 'Nie podano'}\n**Opis:** ${workshop.description}\n**Kategoria:** ${workshop.category}\n**Miejsce:** ${workshop.location}\n**Prowadzący:** ${workshop.instructor || 'Nie dotyczy'}`
    //                 : `Zmienione pola:\n${Object.entries(changes).map(([key, { oldValue, newValue }]) => `**${fieldNamesMap[key]}**: ${oldValue} -> ${newValue}`).join("\n")}`,
    //             color: action === 'add' ? 4586499 : action === 'edit' ? 16579587 : 16515843,
    //         }
    //     ]
    // };

    const message2 = {
        embeds: [
            {
                title: action === 'add' ? "Dodano nowy warsztat" : action === 'delete' ? "Usunięto warsztat" : "Zaktualizowano warsztat",
                description: action === 'add' || action === 'delete'
                    ? `**Tytuł:** ${workshop.title}\n**Dzień:** ${workshop.day}\n**Początek:** ${workshop.startTime}\n**Koniec:** ${workshop.endTime || 'Nie podano'}\n**Opis:** ${workshop.description}\n**Kategoria:** ${workshop.category}\n**Miejsce:** ${workshop.location}\n**Prowadzący:** ${workshop.instructor || 'Nie dotyczy'}`
                    : `Zmienione pola:\n${Object.entries(changes).map(([key, { oldValue, newValue }]) => `**${fieldNamesMap[key]}**: ${oldValue} -> ${newValue}`).join("\n")}`,
                color: action === 'add' ? 4586499 : action === 'edit' ? 16579587 : 16515843,
            }
        ]
    };

    try {
        // await axios.post(DISCORD_WEBHOOK_URL, message);
        await axios.post(DISCORD_WEBHOOK_URL_2, message2);
    } catch (error) {
        console.error('Błąd podczas wysyłania powiadomienia do Discorda:', error);
    }
};
