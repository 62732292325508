import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Calendar from './components/Calendar';
import Login from './components/Login';
import Home from './components/Home';
import { getAuth, onAuthStateChanged } from "firebase/auth";

function App() {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(localStorage.getItem('role') || null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const savedRole = localStorage.getItem('role');
    if (savedRole) {
      setRole(savedRole);
    }
  }, []);

  const handleSetRole = (role) => {
    setRole(role);
    localStorage.setItem('role', role);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Container maxWidth={false} disableGutters>
        <Routes>
          <Route path="/" element={<Home setRole={handleSetRole} />} />
          <Route
            path="/login"
            element={role === 'organizer' ? <Login setUser={setUser} /> : <Navigate to="/" />}
          />
          <Route
            path="/calendar"
            element={
              role === 'participant' || (role === 'organizer' && user) ? (
                <Calendar role={role} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
